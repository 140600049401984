import React, { useEffect, useState } from "react";
import "../../index.css";
import "./style.scss";
import { useLocation } from 'react-router-dom';
import MainHeading from "../../components/DashboardComponent/MainHeading";
import { useStores } from '../../stores';
import Loader from "../../Helper/Loader/Loader";

const IssueDetails = (props) => {
  const location = useLocation();
  const url = location.pathname.split('/').pop();
  const [scrollFlag, setScrollFlag] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [issueData, setIssueData] = useState([])
  const [commentData, setCommentData] = useState([])
  const [issueAttachedFile, setIssueAttachedFile] = useState([])
  const [customerName, setCustomerName] = useState('');

  const { applicantStore } = useStores();

  useEffect(() => {
    setScrollFlag(false)
  }, [scrollFlag])

  useEffect(() => {
    fetchCardDetails();
  }, [])

  const fetchCardDetails = async () => {
    let requestIssueData = {
      method: "GET",
      url: `issue/${url}`
    };
    setIsLoading(true)
    await applicantStore.getServiceRequestData(requestIssueData, (response) => {
      let originalResponse = response?.original?.data
      let currIssueData = originalResponse
      let name = originalResponse.fields?.customfield_10068;
      setCustomerName(name)
      setIssueData(originalResponse)
      let requestData = {
        method: "GET",
        url: `issue/${url}/comment`
      };
      applicantStore.getServiceRequestCommentData(requestData, (response) => {
        let originalResponsenew = response?.original?.data
        setCommentData(originalResponsenew?.comments);
        {
          let fileArray = [];
          (originalResponsenew?.comments || []).map((item) => {
            if (item?.jsdPublic) {
              {
                item?.body?.content.map((element, index) => {
                  if (element?.type == 'paragraph') {
                    if (element?.content != undefined) {
                      for (let i = 0; i < element?.content?.length; i++) {
                        if (!element?.content[i]?.text && element?.content[i]?.text != " \n ") {
                          fileArray.push(element?.content[i].attrs?.url);
                        }
                      }
                    }
                  }
                })
              }
            }
          }
          )
          let allAttachmentArray = [];
          let finalArray = [];
          if (currIssueData?.fields?.attachment?.length > 0) {
            for (let j = 0; j < currIssueData?.fields?.attachment?.length; j++) {

              allAttachmentArray.push(currIssueData.fields.attachment[j].content)
            }

            let found = allAttachmentArray.filter((val, index) => {
              return !fileArray.includes(val)
            })
            setIssueAttachedFile(found);
          }
        }

      });
      setIsLoading(false)
    });
  }

  const renderComment = (item, index) => {
    let commentbox = [];
    switch (item?.type) {
      case 'text':
        if (item?.text != " \n ") {
          if (item?.text != " ") {
            commentbox = (<p className="Activity-content mt-2">
              {item?.content[0]?.text}{"\n"}
            </p>);
          }
        }
        break;
      case 'paragraph':
        if (item?.content != undefined) {
          if (item?.content?.length == 0) {
            commentbox = (<p className="Activity-content mt-2">
              {"\n"}
            </p>);
          }
          for (let i = 0; i < item?.content?.length; i++) {
            if (item?.content[i]?.text && item?.content[i]?.text != " \n ") {
              if (item?.content[i]?.text != " ") {
                commentbox.push(<p className="Activity-content mt-2">
                  {item?.content[i]?.text}{"\n"}
                </p>);
              }
            } else if (item?.content[i]?.text && item?.content[i]?.text != "  ") {
              commentbox.push(<p className="Activity-content mt-2">
                <button onClick={() => { this.downloadFile(item?.content[i]?.attrs?.url.split("/").pop()) }} className="Attachment-Link">Attached Doc: {item?.content[i].attrs?.url.split("/").pop()}</button>
                {" \n "}
              </p>);
            }
          }

          // if (item.content[0]?.text != " \n ") {
          //   if (item.content[0].text != " ") {
          //     commentbox = (<p className="Activity-content mt-2">
          //       {item?.content[0].text}{"\n"}
          //     </p>);
          //   }
          // }
          // if(item.cont)
        }
        break;
      case 'orderedList':
        if (item.content != undefined) {
          for (let i = 0; i < item.content.length; i++) {
            if (item.content[i]?.content[0].content[0].text && item.content[i]?.content[0].content[0].text != " \n ") {
              if (item.content[i].content[0].content[0].text != " ") {
                commentbox.push(<ul style={{ paddingLeft: 16 }}><li className="Activity-content mt-2">
                  {item?.content[i].content[0].content[0].text}{"\n"}
                </li></ul>);
              }
            } else if (item.content[i]?.content[0].content[0].text != " \n ") {
              commentbox.push(<p className="Activity-content mt-2">
                <button onClick={() => { this.downloadFile(item?.content[i].attrs?.url.split("/").pop()) }} className="Attachment-Link">Attached Doc: {item?.content[i].attrs?.url.split("/").pop()}</button>
                {" \n "}
              </p>);
            }
          }
        }
        break;
      case 'bulletList':
        if (item.content != undefined) {
          for (let i = 0; i < item.content.length; i++) {
            if (item.content[i]?.content[0].content[0].text && item.content[i]?.content[0].content[0].text != " \n ") {
              if (item.content[i].content[0].content[0].text != " ") {
                commentbox.push(<ul style={{ paddingLeft: 16 }}><li className="Activity-content mt-2">
                  {item?.content[i].content[0].content[0].text}{"\n"}
                </li></ul>);
              }
            } else if (item.content[i]?.content[0].content[0].text != " \n ") {
              commentbox.push(<p className="Activity-content mt-2">
                <button onClick={() => { this.downloadFile(item?.content[i].attrs?.url.split("/").pop()) }} className="Attachment-Link">Attached Doc: {item?.content[i].attrs?.url.split("/").pop()}</button>
                {" \n "}
              </p>);
            }
          }
        }
        break;
      default:
        if (item?.attrs?.url) {
          commentbox = (<p className="Activity-content mt-2">
            <a href={item?.attrs?.url} style={{ color: 'blue', cursor: 'pointer' }} download>Attached Doc: {item?.attrs?.url.split("/").pop()}</a> {" \n "}
          </p>);
        }
        break;
    }

    return commentbox;

  }

  const downloadFile = (id, e) => {
    // alert("clicked")
    e.preventDefault();
    applicantStore.getUploadedFile(id);
  }


  return (
    <div>
      {scrollFlag &&
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
      {isLoading ? <Loader /> :
        <div className="applicationdetails-container">
          <div className="RightArea">
            <div className="dashboard-area">
              <MainHeading heading={"Issue Details"} />
              <div className="container-fluid dash-container">
                <div className="row">
                  <div className="col-md-12">
                    <form>
                      <div className="form-box">
                        <div className="row form-row">
                          <div className="col-md-2 col-12">
                            <label className="loan-lable">Case ID</label>
                          </div>
                          <div className="col-md-10 col-12">
                            <p className="highlight-case"><span className="mr-md-4">:</span> {issueData?.key}</p>
                          </div>
                        </div>
                        <div className="row form-row">
                          <div className="col-md-2 col-12">
                            <label className="loan-lable">Internal Request</label>
                          </div>
                          <div className="col-md-10 col-12">
                            <p><span className="mr-md-4">:</span> {issueData?.fields?.customfield_10201?.value}</p>
                          </div>
                        </div>
                        <div className="row form-row">
                          <div className="col-md-2 col-12">
                            <label className="loan-lable">Name</label>
                          </div>
                          <div className="col-md-10 col-12">
                            <p><span className="mr-md-4">:</span> {issueData?.fields?.customfield_10068}</p>
                          </div>
                        </div>
                        <div className="row form-row">
                          <div className="col-md-2 col-12">
                            <label className="loan-lable">Mobile</label>

                          </div>
                          <div className="col-md-10 col-12">

                            <p><span className="mr-md-4">:</span> {issueData?.fields?.customfield_10069}</p>
                          </div>
                        </div>
                        <div className="row form-row">
                          <div className="col-md-2 col-12">
                            <label className="loan-lable">Email</label>

                          </div>
                          <div className="col-md-10 col-12">
                            <p><span className="mr-md-4">:</span> {issueData?.fields?.customfield_10070}</p>
                          </div>
                        </div>
                        <div className="row form-row">
                          <div className="col-md-2 col-12">
                            <label className="loan-lable">Service Type</label>

                          </div>
                          <div className="col-md-10 col-12">
                            <p><span className="mr-md-4">:</span> {issueData?.fields?.customfield_10072?.value}</p>
                          </div>
                        </div>
                        <div className="row form-row">
                          <div className="col-md-2 col-12">
                            <label className="loan-lable">Case Loan Number</label>

                          </div>
                          <div className="col-md-10 col-12">
                            <p><span className="mr-md-4">:</span> {issueData?.fields?.customfield_10071}</p>
                          </div>
                        </div>
                        <div className="row form-row">
                          <div className="col-md-2 col-12">
                            <label className="loan-lable">Product Name</label>

                          </div>
                          <div className="col-md-10 col-12">
                            <p><span className="mr-md-4">:</span> {issueData?.fields?.customfield_10094}</p>
                          </div>
                        </div>
                        <div className="row form-row">
                          <div className="col-md-2 col-12">
                            <label className="loan-lable">Product Scheme</label>

                          </div>
                          <div className="col-md-10 col-12">
                            <p><span className="mr-md-4">:</span> {issueData?.fields?.customfield_10102}</p>
                          </div>
                        </div>
                        <div className="row form-row">
                          <div className="col-md-2 col-12">
                            <label className="loan-lable">Branch Name</label>

                          </div>
                          <div className="col-md-10 col-12">
                            <p><span className="mr-md-4">:</span> {issueData?.fields?.customfield_10095}</p>
                          </div>
                        </div>
                        <div className="row form-row">
                          <div className="col-md-2 col-12">
                            <label className="loan-lable">Loan Accounts</label>

                          </div>
                          <div className="col-md-10 col-12">
                            {issueData?.fields?.customfield_10082?.map((item) => {
                              return (
                                <p><span className="mr-md-4">:</span> {item}</p>)
                            })}
                          </div>
                        </div>
                        <div className="row form-row">
                          <div className="col-md-2 col-12">
                            <label className="loan-lable">Description</label>

                          </div>
                          <div className="col-md-10 col-12">

                            <p><span className="mr-md-4">:</span> {issueData?.fields?.description?.content[0]?.content[0]?.text}</p>
                          </div>
                        </div>
                        <div className="row form-row">
                          <div className="col-md-2 col-12">
                            <label className="loan-lable">Summary</label>

                          </div>
                          <div className="col-md-10 col-12">

                            <p><span className="mr-md-4">:</span> {issueData?.fields?.summary}</p>
                          </div>
                        </div>
                        <div className="row form-row">
                          <div className="col-md-2 col-12">
                            <label className="loan-lable">Status</label>
                          </div>
                          <div className="col-md-10 col-12">
                            <p><span className="mr-md-4">:</span> {issueData?.fields?.customfield_10010?.currentStatus?.status}</p>
                          </div>
                        </div>
                        <div className="row form-row">
                          <div className="col-md-2 col-12">
                            <label className="loan-lable">Assignee</label>
                          </div>
                          <div className="col-md-10 col-12">
                            <p><span className="mr-md-4">:</span> {issueData?.fields?.assignee?.displayName}</p>
                          </div>
                        </div>
                      </div>
                      <div className="form-box">
                        <div className="row form-row">
                          <div className="col-md-12 col-12">
                            <p className="loan-subheading">Attached Docs</p>
                          </div>
                          <div className="col-md-10 col-12">
                            {issueAttachedFile?.map((item) =>
                              <div>
                                <button type="button" onClick={(e) => { downloadFile(item?.split("/").pop(), e) }} className="Attachment-Link">Attached Doc: {item?.split("/").pop()}</button>

                              </div>)}
                          </div>
                        </div>
                      </div>
                      <div className="form-box">
                        <div className="row form-row">
                          <div className="col-md-12 col-12">
                            <p className="loan-subheading">Comments</p>
                          </div>
                          <div className="col-md-6 col-12">
                            {/* {console.log(JSON.stringify(commentData))} */}
                            {commentData != undefined && commentData.length !== 0 && commentData?.map((item) => {
                              if (item.jsdPublic) {
                                let commentDate = item.created.split("T")[0];
                                return (
                                  <>
                                    <div className="row all-row chatbox">
                                      <div className="col-md-6 col-6 text-left">
                                        <p className="Activity-sub-text">{item.author.accountType == 'customer' ? customerName : (`(Team UGRO)`)}</p>
                                      </div><div className="col-md-6 col-6 text-right">
                                        <p className="Activity-date">{commentDate}</p>
                                      </div>
                                      <div className="col-md-12 col-12">
                                        {item.body.content.map((element, index) => {
                                          return renderComment(element, index)
                                        })}
                                      </div>
                                    </div>
                                  </>
                                )
                              }
                            }
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default IssueDetails;
