import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom'
import ReactPaginate from "react-paginate";
import ReactExport from "react-export-excel";
import "../../index.css";
import "./style.scss";
import MainHeading from "../../components/DashboardComponent/MainHeading";
import DynamicCard from "../../components/DashboardComponent/DynamicCard";
import ViewIcon from '../../assets/Ugroimages/view.png';
import { useStores } from "../../stores"
import Loader from "../../Helper/Loader/Loader";
import Button from "../../components/UI/Button/Button";
import $ from "jquery";
import notify from "../../shared/notify";
import { GetProjectID } from "../../shared/service/helper";
import ExcelIcon from '../../assets/Ugroimages/excel-icon.png';
import CryptoJS from 'crypto-js'

const projectID = GetProjectID();

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const DashboardCard = (props) => {
  const navigate = useNavigate();
  const { applicantStore } = useStores();
  const [customerData, setCustomerData] = useState([]);
  const [newCustomerList, setNewCustomerList] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [sortType, setSortType] = useState('Select Sorting Type');
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [agentEmail, setAgentEmail] = useState('');
  const [totalResult, setTotalResult] = useState(0);
  const [fromData, setFromData] = useState(0);
  const [tillData, setTillData] = useState(0);
  const [maxData, setMaxData] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20)
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(limit - 1);
  console.log("ejdskj ", endIndex)

  let email = sessionStorage.getItem('email')
  var bytes = CryptoJS.AES.decrypt(email, `${process.env.REACT_APP_ENCRYPTION_KEY}`);
  var agentemail = bytes.toString(CryptoJS.enc.Utf8);
  // let agentemail='shivam.tiwari@ugrocapital.com';


  useEffect(() => {
    getAllIssues(0, 0);
    setAgentEmail(agentemail)
  }, [])

  const getAllIssues = async (startIndex, pageIndex) => {
    setIsLoading(true)
    // setCurrentPage(pageIndex +1)
    let requestData = {
      method: "POST",
      url: `search`,
      data: {
        jql: `'Agent ID' ~ '${agentemail}' AND 'project' = '${projectID}'`,
        // maxResults: maxData,
        // "startAt": startIndex
      }
    };

    applicantStore.getServiceRequestData(requestData, (response) => {
      let originalResponse = response?.original?.data
      setIsLoading(false)
      setCustomerData(originalResponse.issues)
      setNewCustomerList(originalResponse.issues);
      setTotalResult(originalResponse?.total)
      // setFromData(tillData+1)
      // if((fromData+maxData) > maxData){
      //   setTillData(fromData+maxData)
      // }else{
      //   setTillData(maxData)
      // }
      let data = [];
      originalResponse.issues?.map((item) => {
        let obj = {
          CaseId: item.key,
          Name: item.fields.customfield_10068,
          Mobile: item.fields.customfield_10069,
          Email: item.fields.customfield_10070,
          Status: item.fields?.customfield_10010?.currentStatus?.status,
          CreatedOn: item.fields?.created.split("T")[0]
        }
        data.push(obj)
      })
      setExcelData(data)
      let noOfPages = Math.ceil(originalResponse?.total / limit);
      setTotalPages(noOfPages);

    });
  }

  const handleAddCase = () => {
    navigate("/applicant-details")
  }

  const handleExport = () => {
    let data = [];
    // customerData?.map((item) => {
    //   let obj = {
    //     Name: item.fields.customfield_10068,
    //     Mobile: item.fields.customfield_10069,
    //     Email: item.fields.customfield_10070,
    //     Status: item.fields?.customfield_10010?.currentStatus?.status
    //   }
    //   data.push(obj)
    // })
    // setExcelData(data)

  }

  const handleSorting = (type) => {
    FilterbtnClick()
    if (type === 'Select Sorting Type') {
      setCustomerData(newCustomerList)

      return;

    }
    let newCustomerData = [];
    newCustomerList?.map((item, index) => {
      if (item.fields?.customfield_10010?.currentStatus?.status === type) {
        newCustomerData.push(item)
      }
    })
    setSortType(type)
    setCustomerData(newCustomerData)
  }

  const handleFilter = () => {

    let filteredData = [];
    if (fromDate !== '' || toDate !== '') {
      let starting_date = null;
      if (fromDate !== '')
        starting_date = new Date(fromDate)

      let starting_timestamp = null;
      if (starting_date != null)
        starting_timestamp = starting_date.getTime() / 1000;

      let ending_date = null;
      if (toDate !== '')
        ending_date = new Date(toDate);
      let ending_timestamp = null;
      if (ending_date != null)
        ending_timestamp = ending_date.getTime() / 1000;
      let isapplied = false;
      let filteredData_bydate = [];
      newCustomerList.filter(item => {
        let splitDate = item.fields?.created.split("T")[0].split("-").join("-");
        let itemDate = (new Date(splitDate)).getTime() / 1000;
        if (starting_timestamp != null && ending_timestamp != null) {
          if (itemDate >= starting_timestamp && itemDate <= ending_timestamp) {
            filteredData_bydate.push(item);
            isapplied = true;
          }
        }
        else {
          if (starting_timestamp != null) {
            if (itemDate >= starting_timestamp) {
              filteredData_bydate.push(item);
              isapplied = true;

            }
          }
          if (ending_timestamp != null) {
            if (itemDate <= ending_timestamp) {
              filteredData_bydate.push(item);
              isapplied = true;

            }
          }
        }


      });
      if (isapplied) {
        filteredData = filteredData_bydate;// JoinArrays(filteredData, filteredData_bydate);


      }
      else {
        filteredData = [];


      }
      setCustomerData(filteredData)
      FilterbtnClick()
    } else {
      notify('Please select from and to date first', 'danger')
      return;
    }


  }

  const FilterClick = () => {
    $(".filter-hide").toggleClass("filter-show");
    $(".filter-btn-show").toggleClass("filter-btn-hide");
    $(".mob-filter-btn").toggleClass("mob-filter-btn-show");
  }

  const FilterbtnClick = () => {
    $(".filter-hide").removeClass("filter-show");
    $(".filter-btn-show").removeClass("filter-btn-hide");
    $(".mob-filter-btn").removeClass("mob-filter-btn-show");


  }

  return (
    <div className="dashboard-card-container">
      <div className="RightArea">
        {isLoading ? <Loader /> :
          <div className="dashboard-area">
            <MainHeading heading={"Total Application"} />
            <div className="container-fluid card-container">
              <div className="filter-row">
                <div className="Filter">
                  <div className="TabFilterBox">
                    <p>
                      <a onClick={FilterClick} class="filter-show-hide filter-btn-show">
                        <i class="fa fa-filter"></i>  Filter
                      </a>
                      <a class="filter-btn mob-filter-btn" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                        <i class="fa fa-filter"></i>  Filter
                      </a>
                    </p>
                    <div class="collapse show filter-hide" id="collapseExample">
                      <div class="card card-body">
                        <div className="dashboard-date mt-2">
                          <form onSubmit={(e) => { e.preventDefault() }} action="" className="datepicker-input">
                            <div className="datepicker-container submit mr-md-3">
                              <label>{'Sort By Status'}</label>
                              <select
                                key={Math.random()}
                                value={sortType}
                                onChange={(e) => {
                                  handleSorting(e.target.value);
                                }}
                              >
                                <option
                                >
                                  {"Select Sorting Type"}
                                </option>
                                <option
                                >
                                  Work in progress
                                </option>

                                <option
                                >
                                  Open
                                </option>

                                <option
                                >
                                  Closed
                                </option>
                              </select>
                            </div>
                            <div className="mr-md-3 datepicker-container">
                              <label>From</label>
                              <input
                                type="date"
                                name="fromDate"
                                value={fromDate}
                                onChange={(e) => { setFromDate(e.target.value) }}

                              />
                            </div>
                            <div className="datepicker-container">
                              <label>To</label>
                              <input
                                type="date"
                                name="toDate"
                                value={toDate}
                                onChange={(e) => { setToDate(e.target.value) }}
                              />
                            </div>
                            <div className="submit ml-md-3 text-center">
                              <Button
                                type="button"
                                buttonStyle="btn--secondary--solid"
                                buttonSize="btn-medium"
                                onClick={handleFilter}
                              >
                                Filter
                              </Button>
                            </div>

                          </form>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="exebox">
                  <div className="addcasebtn">
                    <Button buttonStyle={"btn--secondary--solid"} onClick={handleAddCase}>Add Case</Button>
                  </div>
                  <div className="exebtnbox">
                    <ExcelFile element={<span style={{ cursor: 'pointer' }} onClick={handleExport}><img src={ExcelIcon} alt="Excel" className="excel-icon" /></span>}>
                      <ExcelSheet data={excelData} name="Sheet1">
                        <ExcelColumn label="Case Id" value="CaseId" />
                        <ExcelColumn label="Name" value="Name" />
                        <ExcelColumn label="Mobile" value="Mobile" />
                        <ExcelColumn label="Email" value="Email" />
                        <ExcelColumn label="Status" value="Status" />
                        <ExcelColumn label="Created On" value="CreatedOn" />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </div>
              </div>
              <div className="row content-row">
                <div className="col-md-12 py-3 px-0">
                  <div class="table-responsive">
                    <div className="data-table">
                      <table className="table  table-bordered" id="issueDetails" width="100%">
                        <thead>
                          <tr>
                            <th>S.no.</th>
                            <th>Case ID</th>
                            <th>Name</th>
                            <th>Mobile</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Creation Date</th>
                            <th>View</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customerData?.map((item, index) => {
                            if (index >= startIndex && index <= endIndex) {
                              return <tr key={Math.random() + 1}>
                                <th>{(index + 1)}</th>
                                <td style={{ cursor: 'pointer' }}><Link to={`/issue-details/${item.key}`} style={{ textDecoration: 'none' }}><span className="primary-theme">{item.key}</span></Link></td>
                                <td>{item.fields.customfield_10068}</td>
                                <td>{item.fields.customfield_10069}</td>
                                <td>{item.fields.customfield_10070}</td>
                                <td>{item.fields?.customfield_10010?.currentStatus?.status}</td>
                                <td>{item.fields?.created.split("T")[0]}</td>
                                <td><Link to={`/issue-details/${item.key}`} ><img src={ViewIcon} alt="View Details" className="actionIcon" /></Link></td>
                              </tr>
                            }
                          })}

                        </tbody>
                      </table>
                      <div className="table-pagination">
                        <div>
                          <p>{`Total ${totalResult} entries`}</p>
                        </div>
                        <div className="pagination-mob">
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={totalPages}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={3}
                            onPageChange={(e) => {

                              let pageNo;
                              if (Number(e.selected) + 1 > currentPage) {
                                pageNo = Number(e.selected) + 1;
                                setCurrentPage(pageNo);
                                let newEndIndex = pageNo * limit - 1;
                                let newStartIndex = (newEndIndex - limit);
                                console.log("pageno ", pageNo, " start ", newStartIndex, " end ", newEndIndex)
                                setStartIndex(newStartIndex + 1)
                                setEndIndex(newEndIndex)
                                // setTillData((pageNo * 50) - 50)
                                // getAllIssues((pageNo * 50) - 50, pageNo)
                              }
                              else {
                                pageNo = Number(e.selected) + 1;
                                if (pageNo < 0) {
                                  pageNo = 1;
                                  setCurrentPage(pageNo);
                                  setStartIndex(1)
                                  setEndIndex(limit - 1)
                                } else {
                                  setCurrentPage(pageNo);
                                  let newEndIndex = pageNo * limit - 1;
                                  let newStartIndex = (newEndIndex - limit);
                                  console.log("pageno ", pageNo, " start ", newStartIndex, " end ", newEndIndex)
                                  setStartIndex(newStartIndex + 1)
                                  setEndIndex(newEndIndex)
                                }
                              }
                              // let pageNo = Number(e.selected) + 1;
                              // setCurrentPage(pageNo);
                              // console.log("selected index value ",pageNo)
                              // getAllIssues(tillData+1,pageNo)                                                            
                            }}
                            containerClassName={
                              "pagination justify-content-center"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                            forcePage={currentPage - 1}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* // <div className="col-md-6">
                  //   <DynamicCard */}
                {/* //     name={item.fields.customfield_10068}
                  //     mobile={item.fields.customfield_10069}
                  //     email={item.fields.customfield_10070}                      
                  //     description={item.fields.description.content[0].content[0].text}
                  //     summary={item.fields.summary}
                  //   />
                  // </div> */}

                {/* // })} */}
              </div>
            </div>
          </div>
        }
      </div>
    </div>

  );
};

export default DashboardCard;
